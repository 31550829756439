<template>
  <v-navigation-drawer app right :width="barWidth" permanent stateless>
    <div v-if="Object.keys(currentChat).length && showInfoBar" class="py-4">
      <!-- this div wraps some sections and adds a margin, possibly need to refactor later, introduced when expandable sections were added to info bar -->
      <div class="mx-4">
        <div class="mb-4 d-flex justify-space-between align-center">
          <div class="subtitle-2">О клиенте</div>
          <v-btn icon @click="closeInfoBar"><v-icon>mdi-close</v-icon></v-btn>
        </div>

        <div class="mb-4">
          <div class="d-flex align-center">
            <div style="position: relative" class="mr-2">
              <span
                v-if="currentChat.isVIP"
                style="
                  position: absolute;
                  top: 0;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 1;
                "
                >👑</span
              >
              <v-tooltip v-if="currentChat.blockedBy" color="red" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    style="
                      position: absolute;
                      z-index: 1;
                      font-size: 58px;
                      left: -1px;
                      top: -1px;
                    "
                  >
                    mdi-block-helper
                  </v-icon>
                </template>
                <span>{{
                  `Отримав бан від ${getSupportNickname(currentChat.blockedBy)}`
                }}</span>
              </v-tooltip>

              <v-avatar color="teal" size="56">
                <img
                  v-if="currentChat.avatar"
                  :src="blobContainerUrl + currentChat.avatar"
                />
                <span v-else class="white--text">{{
                  currentChat.from.first_name.charAt(0).toUpperCase()
                }}</span>
              </v-avatar>
            </div>
            <div>
              <div class="font-weight-medium">
                {{ currentChat.from.first_name }}
                {{ currentChat.from.last_name }}
              </div>
              <!-- Nickname output in telegram  -->
              <v-tooltip color="blue" top>
                <template v-slot:activator="{ on, attrs }">
                  <button
                    @click="copyToClipboard(`@${currentChat.from.username}`)"
                    v-bind="attrs"
                    v-on="on"
                    type="button"
                    class="font-weight-medium nickname-lable"
                    style="font-size: 14px; letter-spacing: 1px"
                  >
                    @{{ currentChat.from.username }}
                  </button>
                </template>
                <span>Скопировать</span>
              </v-tooltip>

              <div style="position: relative">
                <template v-if="currentChat.approvedBy">
                  <v-tooltip color="blue" top>
                    <template v-slot:activator="{ on, attrs }">
                      <button
                        @click="approveUser"
                        v-bind="attrs"
                        v-on="on"
                        type="button"
                        class="font-weight-medium blue--text"
                        style="font-size: 14px; letter-spacing: 1px"
                      >
                        Подтвержденный
                      </button>
                    </template>
                    <span>{{
                      getSupportNickname(currentChat.approvedBy)
                    }}</span>
                  </v-tooltip>
                </template>

                <template v-else>
                  <button
                    @click="approveUser"
                    type="button"
                    class="font-weight-medium grey--text"
                    style="font-size: 14px; letter-spacing: 1px"
                  >
                    Подтвержденный
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <span class="d-flex align-center subtitle-1 font-weight-medium">
            Язык:
            <div class="ml-4 d-flex align-center" style="line-height: 1">
              <span class="font-weight-medium grey--text text--darken-1">{{
                languages[currentChat.language]
              }}</span>
              <img
                class="ml-4"
                width="30"
                height="20"
                :src="flags[currentChat.language]"
              />
            </div>
          </span>
        </div>

        <user-ids-section @edit="showCustomerIdDialog = true" />

        <div>
          <span class="mb-4 d-flex align-center subtitle-1 font-weight-medium">
            Категории
          </span>
          <div v-if="loading">
            <v-skeleton-loader class="px-2" max-height="12rem" type="image" />
          </div>
          <div v-else class="px-2 d-flex flex-wrap align-center">
            <v-tooltip
              top
              v-for="department in departments"
              v-bind:key="department.id"
              :color="department.color"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="handleDepartmentsChange(department.id)"
                  :color="
                    hasDepartment(department.id) ? department.color : 'grey'
                  "
                  :loading="loading"
                  class="ma-1"
                  elevation="0"
                  fab
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ department.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ department.name }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>

      <v-expansion-panels
        v-model="panel"
        class="mb-4 expansion-panels-container"
        flat
        hover
        multiple
        tile
      >
        <v-expansion-panel
          v-for="textArea in textAreasWithSave"
          v-bind:key="textArea.type"
        >
          <v-expansion-panel-header>
            {{ textArea.name }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <text-area-with-save
              :type="textArea.type"
              :dispatch-type="textArea.dispatchType"
              :commit-type="textArea.commitType"
              :title="textArea.name"
              :label="textArea.placeholder"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="isFileSectionEnabled">
          <v-expansion-panel-header>Файлы</v-expansion-panel-header>
          <v-expansion-panel-content>
            <file-section />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Другое</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="mb-4">
              <v-menu offset-y :close-on-content-click="false" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="primary" variant>
                    Личное
                  </v-btn>
                </template>

                <div class="white" style="max-width: 300px; width: 100%">
                  <text-area-with-save
                    type="credentials"
                    dispatch-type="chat/set_credentials"
                    commit-type="chat/SET_CREDENTIALS"
                    title="Логіни та Паролі"
                    label="Логіни та Паролі..."
                  />
                </div>
              </v-menu>
            </div>

            <div class="mb-4">
              <v-btn
                color="primary"
                variant="outlined"
                @click="showCustomerVisitsDialog = true"
              >
                Офисные визиты
              </v-btn>
            </div>

            <div class="mb-4">
              <v-btn
                @click.prevent="sendVipStatus"
                block
                type="submit"
                color="purple"
                outlined
                plain
                class="full-width white--text"
              >
                {{ this.currentChat.isVIP ? 'Убрать VIP' : 'Дать VIP' }}
              </v-btn>
            </div>

            <div class="mb-4">
              <v-btn
                @click.prevent="blockUser"
                block
                type="submit"
                color="red"
                outlined
                plain
                class="full-width white--text"
              >
                {{ this.currentChat.blockedBy ? 'Убрать БАН' : 'Дать БАН' }}
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <assign-user-id-dialog
        :show="showCustomerIdDialog"
        @hide="showCustomerIdDialog = false"
      />
      <customer-visits-dialog
        :show="showCustomerVisitsDialog"
        @close="showCustomerVisitsDialog = false"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import TextAreaWithSave from '@/components/common/TextAreaWithSave';
import FileSection from '@/components/fileSection/FileSection.vue';
import AssignUserIdDialog from './AssignUserIdDialog.vue';
import CustomerVisitsDialog from './CustomerVisitsDialog.vue';
import UserIdsSection from './sections/UserIdsSection.vue';
import { BLOB_CONTAINER_URL, DEPARTMENTS } from '@/lib/const';
import { TEXT_AREAS_WITH_SAVE } from './constants';

import en from '@/assets/flags/en.svg';
import pl from '@/assets/flags/pl.svg';
import uzb from '@/assets/flags/uzb.svg';
import ru from '@/assets/flags/ru.svg';

export default {
  name: 'InfoBar',
  components: {
    TextAreaWithSave,
    FileSection,
    AssignUserIdDialog,
    CustomerVisitsDialog,
    UserIdsSection,
  },

  data() {
    return {
      panel: [],
      approve: false,
      loading: false,

      blobContainerUrl: BLOB_CONTAINER_URL,
      departments: DEPARTMENTS,
      textAreasWithSave: TEXT_AREAS_WITH_SAVE,

      flags: {
        en,
        pl,
        uzb,
        ru,
      },
      languages: {
        en: 'Английский',
        pl: 'Польский',
        uzb: 'Узбецкий',
        ru: 'Русский',
      },
      showCustomerIdDialog: false,
      showCustomerVisitsDialog: false,
    };
  },

  computed: {
    supports() {
      return this.$store.state.support.supports;
    },

    currentSupport() {
      return this.$store.getters['support/currentSupport'];
    },

    showInfoBar: {
      get() {
        return this.$store.state.ui.showInfoBar;
      },

      set(show) {
        this.$store.commit('ui/SET_SHOW_INFO_BAR', show, { root: true });
      },
    },

    barWidth() {
      return Object.keys(this.currentChat).length && this.showInfoBar ? 320 : 0;
    },

    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    isFileSectionEnabled() {
      return this.$store.getters['feature/isFeatureEnabled']('FILE_SECTION');
    },
  },

  methods: {
    hasDepartment(department) {
      return this.currentChat.departments.indexOf(department) >= 0;
    },

    getSupportNickname(supportId) {
      const support = this.supports.find(x => x._id === supportId);
      return support?.nickname || 'Не могу найти никнейм';
    },

    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (error) {
        console.log(error || 'Did not managed to copy the text');
      }
    },

    approveUser() {
      if (!this.approve) {
        this.approve = true;
        setTimeout(() => {
          this.approve = false;
        }, 500);
        return;
      }

      this.$store.dispatch(
        'chat/set_approved_by',
        {
          chatId: this.currentChat.chatId,
          supportId: this.currentSupport._id,
        },
        { root: true }
      );
    },

    closeInfoBar() {
      this.showInfoBar = false;
    },

    async handleDepartmentsChange(department) {
      this.loading = true;

      try {
        if (this.currentChat.departments.indexOf(department) >= 0) {
          await this.removeDepartment(department);
        } else {
          await this.addDepartment(department);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    addDepartment(department) {
      if (this.currentChat.departments.indexOf(department) >= 0) return;

      let departments = [...this.currentChat.departments, department];

      return this.$store.dispatch(
        'chat/set_departments',
        {
          chatId: this.currentChat.chatId,
          departments: departments,
        },
        { root: true }
      );
    },

    removeDepartment(department) {
      if (this.currentChat.departments.indexOf(department) < 0) return;

      let departments = [...this.currentChat.departments];
      departments.splice(departments.indexOf(department), 1);

      return this.$store.dispatch(
        'chat/set_departments',
        {
          chatId: this.currentChat.chatId,
          departments: departments,
        },
        { root: true }
      );
    },

    sendVipStatus() {
      this.$store.dispatch(
        'chat/set_vip_status',
        {
          chatId: this.currentChat.chatId,
          isVIP: !this.currentChat.isVIP,
        },
        { root: true }
      );
    },

    blockUser() {
      this.$store.dispatch(
        'chat/set_blocked_by',
        {
          chatId: this.currentChat.chatId,
          supportId: this.currentSupport._id,
        },
        { root: true }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.nickname-lable {
  cursor: pointer;
  font-weight: 400;
  color: lightgray;
  font-size: 14px;
}

.info,
.additional-info {
  transition: all 0.3s;

  &.saved {
    ::v-deep .v-input__slot {
      box-shadow: 0 3px 1px -2px rgb(0 160 136 / 50%),
        0 2px 2px 0 rgb(0 160 136 / 50%), 0 1px 5px 0 rgb(0 160 136 / 50%) !important;
    }
  }
}

.department-indicator {
  position: absolute;
  background: #9e978e;
  border-radius: 999px;

  &.right,
  &.left {
    height: 1rem;
    width: 1rem;
  }

  &.right {
    top: -4px;
    right: 0;
    background: #000000;
  }

  &.left {
    top: -4px;
    left: 0;
    background: #ff0000;
  }
}

.expansion-panels-container {
  .v-expansion-panel {
    .v-expansion-panel-header {
      padding: 8px 16px;
      font-weight: 500;
      font-size: 1rem;
      letter-spacing: 0.009375em !important;
      line-height: 1.75rem;
    }
  }
}

.theme--dark.v-expansion-panels {
  .v-expansion-panel {
    background-color: #363636;
  }
}
</style>
