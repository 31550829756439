const axios = require('axios');

const headers = {
  headers: {
    Authorization: window.localStorage.getItem('token'),
  },
};
const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const originPath = `${origin}/${prefix}/admins/bulk-messages`;
const routes = {
  get: {
    bulkMessages: originPath,
  },

  post: {
    bulkMessage: originPath,
    cancel: `${originPath}/{bulkMessageId}/cancel`,
    resendBulkMessageItem: `${originPath}/{bulkMessageId}/resend-item`,
  },

  put: {
    bulkMessage: `${originPath}/{bulkMessageId}`,
  },

  delete: {
    bulkMessage: `${originPath}/{bulkMessageId}`,
  },
};

exports.getRoutes = () => {
  return routes;
};

exports.getAll = () => {
  const url = routes.get.bulkMessages;
  return axios.get(url, headers);
};

exports.create = ({
  filters: { userIdsCsv, ...filtersToStringify },
  ...primitiveValues
}) => {
  const url = routes.post.bulkMessage;
  const formData = new FormData();
  Object.entries(primitiveValues).forEach(([key, value]) =>
    formData.append(key, value)
  );
  formData.set('filters', JSON.stringify(filtersToStringify));

  if (userIdsCsv) {
    formData.append('files[]', userIdsCsv);
  }

  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

exports.cancel = bulkMessageId => {
  const url = routes.post.cancel.replace('{bulkMessageId}', bulkMessageId);
  return axios.post(url, {}, headers);
};

exports.resendBulkMessageItem = (bulkMessageId, bulkMessageItemId) => {
  const url = routes.post.resendBulkMessageItem.replace(
    '{bulkMessageId}',
    bulkMessageId
  );
  let data = { bulkMessageItemId };

  return axios.post(url, data, headers);
};

exports.edit = (
  bulkMessageId,
  { filters: { userIdsCsv, ...filtersToStringify }, ...primitiveValues }
) => {
  const url = routes.put.bulkMessage.replace('{bulkMessageId}', bulkMessageId);

  const formData = new FormData();
  Object.entries(primitiveValues).forEach(([key, value]) =>
    formData.append(key, value)
  );
  formData.set('filters', JSON.stringify(filtersToStringify));

  if (userIdsCsv) {
    formData.append('files[]', userIdsCsv);
  }

  return axios.put(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

exports.delete = bulkMessageId => {
  const url = routes.delete.bulkMessage.replace(
    '{bulkMessageId}',
    bulkMessageId
  );
  return axios.delete(url, headers);
};
