export const ORIGIN = `${process.env.VUE_APP_ORIGIN}`;
export const FEATURES = {
  FILE_SECTION: 'FILE_SECTION',
};
export const MONTHS = [
  'Cічень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень',
];

export const BLOB_CONTAINER_URL = `${process.env.VUE_APP_BLOB_URL}${process.env.VUE_APP_BLOB_CONTAINER_NAME}/`;

export const DEPARTMENTS = [
  {
    id: 'survey',
    name: 'Договора',
    color: 'green',
    icon: 'mdi-format-list-bulleted',
  },
  {
    id: 'finances',
    name: 'Финансы',
    color: 'pink',
    icon: 'mdi-finance',
  },
  {
    id: 'assignId',
    name: 'Приписать ключ',
    color: 'cyan',
    icon: 'mdi-key',
  },
  {
    id: 'legalization',
    name: 'Кадры ЛГ',
    color: 'orange',
    icon: 'mdi-passport',
  },
  {
    id: 'legalizationDocuments',
    name: 'Wypis/ID ЛГ',
    color: 'red',
    icon: 'mdi-taxi',
  },
  {
    id: 'register',
    name: 'Регистрации',
    color: 'purple lighten-3',
    icon: 'mdi-account-plus',
  },
  {
    id: 'lead',
    name: 'Лиды',
    color: 'indigo',
    icon: 'mdi-cash',
  },
  {
    id: 'call',
    name: 'Звонки',
    color: 'teal',
    icon: 'mdi-phone',
  },
  {
    id: 'uzb',
    name: 'UZB',
    color: 'teal',
    icon: '🇺🇿',
    hasComplexFilter: true,
  },
  {
    id: 'parking',
    name: 'Автопарки',
    color: 'blue-grey darken-2',
    icon: 'mdi-parking',
    hasComplexFilter: true,
  },
  {
    id: 'sheriff',
    name: 'Шериф',
    color: 'orange lighten-2',
    icon: 'mdi-police-badge-outline',
  },
  {
    id: 'leadFollowUp',
    name: 'Чаты',
    color: 'pink lighten-2',
    icon: 'mdi-chat-alert-outline',
  },
  {
    id: 'archiveFinances',
    name: 'Архив Финансы',
    color: 'blue-grey darken-1',
    icon: 'mdi-finance',
  },
  {
    id: 'archiveLegalization',
    name: 'Архив Кадры ЛГ',
    color: 'blue-grey darken-1',
    icon: 'mdi-passport',
  },
  {
    id: 'archiveLegalizationDocuments',
    name: 'Архив Wypis/ID ЛГ',
    color: 'blue-grey darken-1',
    icon: 'mdi-taxi',
  },
  {
    id: 'archiveRegister',
    name: 'Архив Регистрации',
    color: 'blue-grey darken-1',
    icon: 'mdi-account-plus',
  },
  {
    id: 'archiveLeadFollowUp',
    name: 'Архив Чаты',
    color: 'blue-grey darken-1',
    icon: 'mdi-chat-alert-outline',
  },
];

export const LEAD_FOLLOW_UP_VOTE_OPTION = {
  CLARIFICATION: 'clarification',
  CONDITIONS: 'conditions',
  CONSULTATION: 'consultation',
  NOT_INTERESTED: 'notInterested',
};
